<template>
  <a :href="data.href" :target="target">
    <slot name="default"> {{ label }} </slot>
  </a>
</template>

<script>
export default {
  props: {
    label: String,
    target: {
      type: String,
      default: '_blank',
    },
    data: {
      type: Object,
      default: () => ({
        href: '',
      }),
    },
  },
}
</script>

<style lang="scss"></style>
