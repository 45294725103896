<template>
  <div id="app" class="route" :class="['route--' + view.toLowerCase()]">
    <div
      v-if="$route.meta.navigation !== false"
      class="nav"
      :class="[
        'nav--' + view.toLowerCase(),
        {
          ['nav--' + view.toLowerCase() + '-expanded']: isExpanded,
          [`nav--${view.toLowerCase()}-${slug}`]: view.toLowerCase() == 'post',
        },
      ]"
    >
      <div class="container">
        <NavigationComponent
          :logo="logo"
          :items="items"
          :title="pageName"
          padding="10px 0"
          :action="mainAction || redirect(['Page', 'Residential'], { scrollTo: 0 })"
          :isOpen.sync="isExpanded"
        >
          <ButtonComponent
            class="nav-custom__button"
            theme="primary"
            :label="phone"
            tag="a"
            :href="`tel:${phone}`"
            target="_blank"
            :icon="['fal', 'phone-alt']"
            icon-placement="left"
          />
        </NavigationComponent>
      </div>
    </div>
    <router-view />
    <FooterComponent v-if="$route.meta.footer !== false" :navigation="items" :cookie-manager="CM" />
    <AssetComponent />
  </div>
</template>

<script>
import { NavigationComponent } from 'vue-elder-navigation'
import { mapState, mapActions } from 'vuex'
import AssetComponent, { EventBus as AssetBus } from '@kvass/asset-manager'
import FooterComponent from '@/components/Footer'
import API from './api'
import BrowserApiMixin from '@/mixins/browser-api'
import loadCM from '@/gdpr'

export default {
  mixins: [BrowserApiMixin('app')],
  data() {
    return {
      isOnTop: true,
      customItems: [],
      isExpanded: false,
      CM: {},
    }
  },
  computed: {
    ...mapState('Root', ['item']),
    slug() {
      return this.$route.params.slug
    },
    logos() {
      return {
        light: API.logoInverted,
        dark: API.logo,
      }
    },
    isSingular() {
      return API.projectType === 'singular'
    },
    view() {
      return this.$path('name', this.$route) || ''
    },
    logo() {
      return this.logos.dark
    },
    posts() {
      return this.item.posts || []
    },
    labels() {
      return KvassConfig.get('customLabels') || {}
    },
    phone() {
      return this.$path('customFields.nav-phone', this.item) || '99 99 99 99'
    },
    pageName() {
      return this.item.name || ''
    },
    seo() {
      return this.$path('customFields.seo', this.item) || {}
    },

    hasAttachments() {
      if (!this.isSingular) return this.$path('media.attachments.length', this.item)
      return this.$path('$store.state.Residential.item.media.attachments.length')
    },
    hasAttachments() {
      return this.$path('$store.state.Residential.item.media.attachments.length')
    },

    brochure() {
      return this.$path('media.brochure', this.item) || []
    },
    brochureStrategy() {
      return KvassConfig.get('siteSettings.brochureDownloadStrategy') || 'direct'
    },

    items() {
      return [...this.defaultItems, ...this.customItems].sort((a, b) => a.order - b.order)
    },
    addons() {
      return this.$path('item.metadata.addons') || []
    },
    redirectUrl() {
      return (
        this.$path('item.publishing.redirectUrl') &&
        `https://${this.$path('item.publishing.redirectUrl')}`
      )
    },
    mainAction() {
      if (this.addons.includes('project:flatfinder')) return this.redirectUrl
      if (this.isSingular) return
      return this.redirect('Page', { scrollTo: 0 })
    },

    defaultItems() {
      return [
        {
          condition: this.posts.find(
            i => (i.customFieldsConfig || [])[0].source === 'about-krogsveen',
          ),
          label: 'Om oss',
          action: this.redirect(['Page'], {
            hash: '#section-about',
          }),
        },
        {
          condition:
            this.$path('item.customFields.description') ||
            this.$path('customFields.sections.0.description') ||
            this.$path('customFields.sections.0.image') ||
            this.$path('customFields.gallery.length'),

          label: 'Prosjekt',
          action: this.redirect(['Page'], {
            hash: '#section-project',
          }),
        },
        {
          condition: this.$path('customFields.process-steps.0', this.item),
          label: 'Prosess',
          action: this.redirect(['Page'], {
            hash: '#section-process',
          }),
        },
        {
          condition: (this.$path('customFields.delivery', this.item) || []).length,
          label: 'Leveranse',
          action: this.redirect(['Page'], {
            hash: '#section-delivery',
          }),
        },
        {
          condition: this.posts.find(
            i => (i.customFieldsConfig || [])[0].source === 'offer-krogsveen',
          ),
          label: 'Tilbud',
          action: this.redirect(['Page'], {
            hash: '#section-offer',
          }),
        },

        {
          label: 'Vedlegg',
          action: this.redirect(['Page', 'Residential'], {
            hash: '#section-attachments',
          }),
          condition: this.hasAttachments,
        },
        {
          condition: this.$path('customFields.reference-projects.0.image', this.item),
          label: 'Referanser',
          action: this.redirect(['Page'], {
            hash: '#section-references',
          }),
        },
        ...this.posts.map(i => {
          if (!this.$path('customFields.page-settings.display-in-menu', i)) return {}
          return {
            label: i.title,
            exact: true,
            action: this.redirect(['Post'], { params: { slug: i.slug }, forceRedirect: true }),
          }
        }),
      ]
        .filter(x => !('condition' in x) || x.condition)
        .filter(x => x.action || (x.items && x.items.length))
        .map((i, index) => ({ ...i, order: (index + 1) * 10 }))
    },
  },
  methods: {
    ...mapActions('Root', ['fetch']),
    redirect(name, options) {
      return async function() {
        name = name instanceof Array ? name : [name]

        let { action, scrollTo, hash, params } = options

        if (!name.includes(this.$route.name) || params?.slug != this.$route.query?.slug)
          await this.$router.push({ name: name[0], hash, params })
        if (hash) {
          let target = document.querySelector(
            '.scroll-anchor--type-anchor.scroll-anchor--value-' + hash.substring(1),
          )
          if (!target) return
          target.scrollIntoView({ behavior: 'smooth' })
        }
        if (action) return this.action()
        if (scrollTo !== undefined) window.scrollTo(0, scrollTo)
      }
    },
  },
  created() {
    this.fetch().then(() => (this.CM = loadCM(this)))
  },
  metaInfo() {
    return {
      titleTemplate: this.pageName
        ? `%s | ${this.$path('seo.title') || this.pageName}`
        : `${this.$t('loading', { resource: null })}...`,
      link: [
        { rel: 'preload', href: this.logos.dark, as: 'image' },
        { rel: 'preload', href: this.logos.light, as: 'image' },
        { rel: 'icon', href: API.favicon, type: 'image/png' },
      ],
    }
  },
  components: {
    NavigationComponent,
    FooterComponent,
    AssetComponent,
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.nav {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: get-layer('nav');
  background: white;
  box-shadow: 0 10px 15px -10px rgba(black, 0.05);

  transition: background 150ms ease-in;

  &-custom__button {
    width: 100%;

    .elder-button__label {
      text-align: start;
    }
  }

  .route--page.page--is-scrolled & {
    box-shadow: 0 10px 15px -10px rgba(black, 0.05);
  }

  .elder__navigation {
    .elder-button__label {
      padding: 8px 15px;
    }
    .elder-button--icon-left .elder-button__icon {
      padding: 8px 0px 8px 15px;
    }
    &-bars {
      color: $dark;
    }
    &-component {
      color: $dark;
      font-weight: 300;
      padding: 8px 15px;
    }
  }
  .container {
    max-width: initial;
  }

  .elder__navigation-dropdown .elder-dropdown__content .elder__navigation-component {
    color: var(--primary-contrast, #444) !important;
  }

  .elder__navigation-component {
    &.elder__navigation-component--primary {
      background-color: var(--primary) !important;
      color: var(--primary-contrast) !important;
    }
  }
  .elder__navigation-logo-image {
    max-height: 46px !important;
  }

  &-expanded {
    box-shadow: none;
    background: linear-gradient(180deg, #000 -200%, #00000036);
    backdrop-filter: blur(5px);
    color: white;
    padding-bottom: 2rem;
  }
}
</style>
