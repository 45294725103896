<template>
  <div class="process-item">
    <div class="process-item__description" v-html="data.description"></div>
    <div v-if="data && data.icons.length" class="process-item__content container">
      <div v-for="(item, index) in data.icons" :key="index" class="process-item__item">
        <div
          class="process-item__item-icon"
          :style="{ backgroundImage: `url(${item.url})` }"
          :title="item.description"
        ></div>

        <div class="process-item__item-description">
          {{ item.description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
  },
  computed: {},
}
</script>

<style lang="scss">
.process-item {
  $line: 2px solid $dark;
  $lineHeight: 1.3em;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  $iconSize: 70px;
  padding: 6rem 4rem;
  align-items: center;

  h2 {
    font-size: 28px;
  }

  @include respond-below('phone') {
    gap: 2rem;
    padding: 3rem 2rem;
  }

  &:nth-child(odd) {
    background-color: var(--background-color);
  }

  &__description {
    text-align: center;
    max-width: 550px;
  }

  &__content {
    display: flex;
    justify-content: center;
    gap: 2rem;

    @include respond-below('phone') {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      font-size: 0.7em;
    }
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0;
    gap: 1rem;

    @include respond-below('phone') {
      padding: 0.2rem;
    }

    &-icon {
      width: $iconSize;
      height: $iconSize;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      padding: 1.1rem;
      background-origin: content-box;
      @include respond-below('phone') {
        width: calc(#{$iconSize}/ 1.2);
        height: calc(#{$iconSize}/ 1.2);
        font-size: 0.8em;
        svg {
          font-size: 0.8em;
        }
      }
      border: $line;
      border-radius: 50%;
    }

    &-description {
      text-align: center;
    }
  }
}
</style>
