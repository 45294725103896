<template>
  <footer class="footer">
    <div class="footer__quality-stamp">
      <hr />
      <img v-if="qualityStamp" :src="qualityStamp.url" :alt="qualityStamp.name" />
      <hr />
    </div>

    <div class="footer__container-top container">
      <div>
        <!-- <img class="footer__logo" :src="logo" width="220" :alt="item.name" /> -->
        <div class="footer__menu">
          <div class="footer__title">{{ $t('menu') }}</div>
          <template v-for="(navItem, index) in navigation">
            <ActionComponent
              v-if="$path('action.name', navItem) !== $path('name', $route)"
              class="footer__menu-action"
              :class="{ 'footer__menu-action--empty': !navItem.action }"
              v-bind="navItem"
              :key="index"
            />
            <template v-if="navItem.items && navItem.items.length">
              <ActionComponent
                v-for="(nested, ni) in navItem.items"
                class="footer__menu-action footer__menu-action--indent"
                v-bind="nested"
                :key="index + '_' + ni"
              />
            </template>
          </template>
        </div>
      </div>
      <div class="footer__partners" v-if="partners.length">
        <div class="footer__partners-description">{{ partners.description }}</div>

        <div class="footer__partners-logos">
          <template v-for="partner in partners.logos">
            <img :key="partner.name" :src="partner.url" width="200" :alt="partner.name" />
          </template>
        </div>
      </div>
    </div>

    <div class="container">
      <hr class="footer__hr-bottom" />

      <div class="footer__container-bottom">
        <div class="footer__copyright">
          <div>
            &copy; {{ year }}
            <a href="https://www.krogsveen.no/" target="_blank">Krogsveen</a>
            - {{ $t('pageMadeBy') }} <a href="https://www.kvass.no" target="_blank">Kvass</a>
          </div>
        </div>
        <div class="footer__links">
          <a href="/api/legal/privacy/tenant" target="_blank"
            >{{ $t('privacyPolicy') }} / Informasjonskapsler
          </a>
          <a href="#" @click.prevent="editConsents">{{ $t('editConsents') }}</a>
        </div>
        <div class="footer__some" v-if="someCustomField.length !== 0">
          <template v-for="(item, index) in someCustomField">
            <a :key="index" :href="item.url" target="_blank">{{ item.label }}</a>
          </template>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
import ActionComponent from 'vue-elder-navigation/src/action'
import API from '@/api'
import FooterContent from '@kvass/template-footer-content'
import { GetCustomField, ScrollToAnchor } from '@/utils'
import CM from '@/gdpr'

const someMap = {
  facebook_url: 'Facebook',
  instagram_url: 'Instagram',
  linkedin_url: 'LinkedIn',
  twitter_url: 'Twitter',
}

export default {
  props: {
    navigation: Array,
    cookieManager: {
      type: Object,
      defualt: () => ({}),
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
  computed: {
    ...mapState('Root', ['item']),
    partners() {
      return GetCustomField('partners', this.item.customFields) || []
    },
    qualityStamp() {
      return GetCustomField('quality-stamp', this.item.customFields) || {}
    },

    someCustomField() {
      if (!this.item) return
      let items = GetCustomField('social-media', this.item.customFields) || {}

      let newsLetter = {
        url: GetCustomField('newsletter-link', this.item.customFields),
        label: 'Nyhetsbrev',
      }

      return [
        ...Object.keys(items).map(i => {
          return {
            url: items[i],
            label: someMap[i],
          }
        }),
        newsLetter,
      ].filter(i => Boolean(i.url))
    },
    address() {
      return this.item.address
    },
    mapLink() {
      return GetCustomField('map-link', this.item.customFields)
    },
    logo() {
      return API.logo
    },
  },
  methods: {
    editConsents() {
      this.cookieManager.show()
    },
    navigate() {
      ScrollToAnchor('lead')
    },
  },
  components: {
    ActionComponent,
    FooterContent,
  },
}
</script>

<style lang="scss">
.footer {
  background-color: var(--light-grey);
  padding-top: 5rem;
  &__container {
    &-top {
      display: flex;
      justify-content: space-between;
      padding-bottom: 3rem;
      & > div:nth-child(1) {
        justify-content: space-between;
        display: flex;
        gap: 7rem;

        @include respond-below('phone') {
          flex-direction: column;
          align-items: flex-start;
          gap: 2rem;
        }
      }

      @include respond-below('phone') {
        gap: 3rem !important;
      }
    }
    &-bottom {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 2rem 0rem !important;
      gap: 1rem;
      font-size: 16px;
    }
  }

  .container {
    gap: 2rem;
    @include respond-below('phone') {
      flex-direction: column;
      padding: 1rem 1rem !important;
    }
  }

  &__logo {
    align-self: center;
    @include respond-below('phone') {
      align-self: flex-start;
      width: 150px;
    }
  }

  h3 {
    margin-bottom: 1rem;
    @include respond-below('phone') {
      margin-bottom: 0;
    }
  }

  a {
    color: inherit;
  }

  p {
    margin: 0;
  }

  &__address {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    a {
      text-decoration: underline;
    }
  }

  &__partners {
    display: flex;
    flex-direction: column;

    gap: 1rem;
    margin-right: 2rem;

    &-logos {
      flex-wrap: wrap;
      display: flex;
      gap: 3rem;
      @include respond-below('phone') {
        gap: 2rem;
        img {
          width: 140px;
        }
      }
    }
  }

  &__links {
    display: flex;
    gap: 2rem;
    @include respond-below('phone') {
      flex-direction: column;
      gap: 1rem;
    }
  }

  &__social-icons {
    a {
      margin-right: 0.8rem;
    }
  }

  &__some {
    display: flex;
    gap: 0.5rem;
  }
  &__quality-stamp {
    display: flex;
    $size: 92px;

    img {
      position: relative;
      margin: 0 10px;
      top: $size / -2;
      width: $size;
    }
  }
  &__menu {
    display: flex;
    flex-direction: column;

    &-action {
      padding: 0;
      background-color: transparent !important;
      color: inherit;
      font-weight: inherit;
      & div {
        border-bottom: 1px solid $grey;
      }

      & div:hover {
        cursor: pointer;
        border-color: var(--primary);
      }

      & + & {
        margin-top: 0.7rem;
      }

      &--indent {
        margin-left: 0.5rem;
      }
    }
  }

  &__title {
    font-weight: 500;
    display: block;
    margin-bottom: 1rem;
  }
  a {
    text-decoration: none;
    font-size: 16px;
  }
}
</style>
