var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"route",class:['route--' + _vm.view.toLowerCase()],attrs:{"id":"app"}},[(_vm.$route.meta.navigation !== false)?_c('div',{staticClass:"nav",class:[
      'nav--' + _vm.view.toLowerCase(),
      {
        ['nav--' + _vm.view.toLowerCase() + '-expanded']: _vm.isExpanded,
        [`nav--${_vm.view.toLowerCase()}-${_vm.slug}`]: _vm.view.toLowerCase() == 'post',
      },
    ]},[_c('div',{staticClass:"container"},[_c('NavigationComponent',{attrs:{"logo":_vm.logo,"items":_vm.items,"title":_vm.pageName,"padding":"10px 0","action":_vm.mainAction || _vm.redirect(['Page', 'Residential'], { scrollTo: 0 }),"isOpen":_vm.isExpanded},on:{"update:isOpen":function($event){_vm.isExpanded=$event},"update:is-open":function($event){_vm.isExpanded=$event}}},[_c('ButtonComponent',{staticClass:"nav-custom__button",attrs:{"theme":"primary","label":_vm.phone,"tag":"a","href":`tel:${_vm.phone}`,"target":"_blank","icon":['fal', 'phone-alt'],"icon-placement":"left"}})],1)],1)]):_vm._e(),_c('router-view'),(_vm.$route.meta.footer !== false)?_c('FooterComponent',{attrs:{"navigation":_vm.items,"cookie-manager":_vm.CM}}):_vm._e(),_c('AssetComponent')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }