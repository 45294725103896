import { library } from '@fortawesome/fontawesome-svg-core'

/* Solid icons */
import { faTh as fasTh } from '@fortawesome/pro-solid-svg-icons/faTh'
import { faExclamationCircle as fasExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle'
import { faArrowRight as fasArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight'
import { faArrowLeft as fasArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft'

library.add(fasTh, fasExclamationCircle, fasArrowRight, fasArrowLeft)

/* Regular icons */
import { faArrowDown as farArrowDown } from '@fortawesome/pro-regular-svg-icons/faArrowDown'
import { faArrowUp as farArrowUp } from '@fortawesome/pro-regular-svg-icons/faArrowUp'
import { faArrowRight as farArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight'
import { faArrowLeft as farArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft'
import { faAngleDown as farAngleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDown'
import { faPen as farPen } from '@fortawesome/pro-regular-svg-icons/faPen'
import { faExternalLinkAlt as farExternalLinkAlt } from '@fortawesome/pro-regular-svg-icons/faExternalLinkAlt'

library.add(
  farArrowDown,
  farArrowRight,
  farArrowLeft,
  farPen,
  farArrowUp,
  farAngleDown,
  farExternalLinkAlt,
)

/* Light icons */
import { faExclamationCircle as falExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle'
import { faCalendar as falCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar'
import { faEnvelope as falEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope'
import { faPhoneAlt as falPhoneAlt } from '@fortawesome/pro-light-svg-icons/faPhoneAlt'
import { faPlus as falPlus } from '@fortawesome/pro-light-svg-icons/faPlus'
import { faRulerTriangle as falRulerTriangle } from '@fortawesome/pro-light-svg-icons/faRulerTriangle'
import { faTag as falTag } from '@fortawesome/pro-light-svg-icons/faTag'
import { faSortNumericUp as falSortNumericUp } from '@fortawesome/pro-light-svg-icons/faSortNumericUp'
import { faCheckSquare as falCheckSquare } from '@fortawesome/pro-light-svg-icons/faCheckSquare'
import { faToggleOn as falToggleOn } from '@fortawesome/pro-light-svg-icons/faToggleOn'
import { faTimes as falTimes } from '@fortawesome/pro-light-svg-icons/faTimes'

library.add(
  falExclamationCircle,
  falCalendar,
  falEnvelope,
  falPhoneAlt,
  falPlus,
  falRulerTriangle,
  falTag,
  falSortNumericUp,
  falToggleOn,
  falCheckSquare,
  falTimes,
)

/* Brand icons */
import { faFacebook as fabFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'
import { faInstagram as fabInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faLinkedin as fabLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin'
import { faTwitter as fabTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'

library.add(fabFacebook, fabInstagram, fabLinkedin, fabTwitter)

/* Duotone icons */
import { faSyncAlt as fadSyncAlt } from '@fortawesome/pro-duotone-svg-icons/faSyncAlt'
import { faAngleDown as fadAngleDown } from '@fortawesome/pro-duotone-svg-icons/faAngleDown'
import { faSpinnerThird as fadSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird'
import { faFilePdf as fadFilePdf } from '@fortawesome/pro-duotone-svg-icons/faFilePdf'
import { faFileAlt as fadFileAlt } from '@fortawesome/pro-duotone-svg-icons/faFileAlt'
import { faFileImage as fadFileImage } from '@fortawesome/pro-duotone-svg-icons/faFileImage'

library.add(fadSyncAlt, fadAngleDown, fadSpinnerThird, fadFilePdf, fadFileAlt, fadFileImage)
