<template>
  <Loader :value="promises.fetch" theme="default" class="page-page">
    <Blocks ref="blocks" :blocks="config" :custom-components="customComponents" />
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CustomComponents from '@/components/Custom'

import BrowserApiMixin from '@/mixins/browser-api'
import { GetCustomField } from '@/utils'
import { Blocks } from '@kvass/pagebuilder'
import Config from '../config/page'
import { animate, inView, stagger } from 'motion'

export default {
  mixins: [BrowserApiMixin('page')],

  computed: {
    ...mapState('Page', ['promises', 'item']),
    config() {
      return Config.call(this)
    },
    customComponents() {
      return CustomComponents
    },
  },

  methods: {
    ...mapActions('Page', ['fetch']),
    GetCustomField,
    async initAnimation() {
      animate(
        '.hero__text > *',
        { opacity: 1, transform: 'none' },
        { duration: 0.8, delay: stagger(0.3) },
      )

      inView('.kpb-section', ({ target }) => {
        animate(target, { opacity: 1, transform: 'none' }, { duration: 0.8 })
      })
      inView('.process-item', ({ target }) => {
        animate(target, { opacity: 1, transform: 'none' }, { duration: 0.8 })
      })
    },
  },
  created() {
    this.fetch()
  },
  mounted() {
    this.promises.fetch.then(() => this.$nextTick(() => this.initAnimation()))
  },

  metaInfo() {
    return {
      title: this.$t('home'),
    }
  },

  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.page-page {
  @for $i from 1 through 15 {
    & > *:nth-child(#{$i}) {
      order: $i * 10;
    }
  }
  .process-item {
    opacity: 0;
  }
  .kpb-section {
    transform: translateX(-3rem);
    opacity: 0;
  }
  .hero {
    $height: 100vh;
    $responsiveHeight: 60vh;

    position: relative;

    width: 100%;
    display: flex;
    flex-direction: column;

    img {
      object-position: center;
    }

    iframe {
      min-height: $height;
      min-width: 250vh;
    }

    @include respond-below('phone') {
      height: $responsiveHeight;

      iframe {
        min-height: $responsiveHeight;
      }
    }

    .hero__text {
      top: 0;
      left: 0;
      position: absolute;
      z-index: 2;
      bottom: initial;
      right: initial;
      aspect-ratio: 16/9;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      text-align: center;
      background-color: css-alpha('primary', 40%);

      @include respond-below('phone') {
        height: $responsiveHeight;
      }
      & > * {
        opacity: 0;
        transform: translateX(-5rem);
      }
    }

    .hero__text {
      h1 {
        margin-bottom: 3rem;
        font-size: 5.65rem;
        max-width: 60vw;
        @include respond-below('tablet') {
          font-size: 3rem;
          max-width: 90vw;
        }
        @include respond-below('phone') {
          font-size: 2rem;
        }
      }
      p {
        margin-top: 0;
      }
      a {
        margin: 0 0.5rem;
        &[data-cta='primary'] {
          background: white;
          color: black;
          font-weight: 500;
        }
        &[data-cta='secondary'] {
          font-weight: 500;
          background: transparent;
          color: white;
          border: 1px solid white;
        }
      }
    }
  }

  .section-agenda {
    .kpb-flex {
      flex-direction: row;
      @include respond-below('phone') {
        gap: 2rem;
      }
    }
  }

  .section-references {
    img {
      position: absolute;
      width: 100%;
      background-repeat: no-repeat;
      height: 100%;

      transition: transform 0.2s; /* Animation */
      &:hover {
        transform: scale(1.05);
      }
    }
  }
  .section-project {
    .kpb-image:nth-child(2) {
      grid-row-end: span 2;
    }
  }

  .section-delivery {
    .kvass-image-text {
      &__item {
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem 6rem;
        @media (max-width: 850px) {
          grid-template-columns: 1fr;
          padding: 0rem;
        }
      }

      .kvass-media-render-image__image {
        aspect-ratio: initial;
      }
    }
  }
}
</style>
