function transformContent(content, transform = ['h2', 'h1']) {
  if (!content) return
  return content.replace(transform[0], transform[1])
}

export default function() {
  // const get = path => this.$path(path, this.$store.state)

  const get = ({ path, source = 'Page.item.posts', customFieldsConfig }) => {
    let data = this.$path(source, this.$store.state) || []
    if (source.includes('posts')) {
      data = data.find(p => p.customFieldsConfig[0].source === customFieldsConfig)
      if (!data) return
    }

    return this.$path(path, data)
  }

  return [
    {
      component: 'Slider',
      condition: get({ path: 'customFields.cover', source: 'Page.item' }),
      props: {
        aspectRatio: '16/9',
        class: 'hero',
        options: {
          manuelNavigation: false,
        },
      },
      data: [get({ path: 'customFields.cover', source: 'Page.item' })],
      blocks: {
        default: [
          {
            component: 'Text',
            condition: get({ path: 'customFields.slogan', source: 'Page.item' }),
            props: {
              class: 'hero__text',
            },
            data: {
              content: transformContent(get({ path: 'customFields.slogan', source: 'Page.item' })),
            },
          },
        ],
      },
    },

    {
      condition: (get({ path: 'customFields.agenda', source: 'Page.item' }) || []).filter(
        i => i.case,
      ).length,
      component: 'Section',
      props: {
        width: 'medium',
        id: 'section-agenda',
        class: 'section-agenda np-b',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: '<h2>Agenda</h2>',
            },
            props: {
              class: 'heading',
            },
          },

          {
            component: 'Grid',
            props: {
              gap: '2rem',
              columns: '1fr',
              flexDirection: 'column',
              style: 'justify-content: center;padding:0 2rem;',
            },
            blocks: {
              default: [
                ...(get({ path: 'customFields.agenda', source: 'Page.item' }) || []).map(item => {
                  return {
                    component: 'Flex',
                    props: {
                      gap: '4rem',
                      justifyContent: 'space-between',
                      style: 'font-size:22px;',
                    },
                    blocks: {
                      default: [
                        {
                          condition: item.from,
                          component: 'Text',
                          condition: item.from || item.to,
                          data: {
                            content: item.from
                              ? `<span>Kl. ${transformContent(item.from, [':', '.'])} </span>`
                              : '' +
                                `${
                                  item.to
                                    ? `- <span>${transformContent(item.to, [':', '.'])}</span>`
                                    : ''
                                }`,
                          },
                        },
                        {
                          component: 'Text',
                          data: {
                            content: `<span>${item.case}</span>`,
                          },
                        },
                      ].filter(f => !('condition' in f) || f.condition),
                    },
                  }
                }),
              ],
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get({
        path: 'title',
        customFieldsConfig: 'about-krogsveen',
      }),
      props: {
        width: 'full',
        id: 'about',
        class: 'section-image-text np-b',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: `<h2>${get({
                path: 'title',
                customFieldsConfig: 'about-krogsveen',
              })}</h2>`,
            },
            props: {
              class: 'heading',
              style: 'max-width: 1400px;padding-inline: 1rem; width: 100%; margin-inline: auto;',
            },
          },
          {
            component: 'ImageText',
            data: {
              content: get({ path: 'customFields.about', customFieldsConfig: 'about-krogsveen' }),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition:
        get({ path: 'customFields.description', source: 'Page.item' }) ||
        get({ path: 'customFields.sections.0.description', source: 'Page.item' }) ||
        get({ path: 'customFields.sections.0.image', source: 'Page.item' }) ||
        (get({ path: 'customFields.gallery', source: 'Page.item' }) || []).length,
      props: {
        width: 'medium',
        id: 'project',
        class: 'section-project np-b',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: '<h2>Prosjekt</h2>',
            },
            props: {
              class: 'heading',
            },
          },
          {
            component: 'Text',
            data: {
              content: get({ path: 'customFields.description', source: 'Page.item' }),
            },
            props: {
              class: 'intro',
            },
          },
          {
            component: 'ImageText',
            condition:
              get({ path: 'customFields.sections.0.description', source: 'Page.item' }) ||
              get({ path: 'customFields.sections.0.image', source: 'Page.item' }),
            data: {
              content: get({ path: 'customFields.sections', source: 'Page.item' }),
            },
            props: {
              style: 'margin-bottom: 4rem',
            },
          },

          {
            component: 'Grid',
            props: {
              gap: '2rem',
              justifyContent: 'center',
            },
            blocks: {
              default: [
                ...(get({ path: 'customFields.gallery', source: 'Page.item' }) || []).map(i => {
                  return {
                    component: 'Image',
                    data: {
                      image: i,
                    },
                  }
                }),
              ],
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition:
        get({ path: 'customFields.process.description', source: 'Page.item' }) ||
        get({ path: 'customFields.process.images.0', source: 'Page.item' }) ||
        get({ path: 'customFields.process-steps.0.description', source: 'Page.item' }) ||
        get({ path: 'customFields.process-steps.0.images.0', source: 'Page.item' }) ||
        get({ path: 'customFields.process-images-below.0', source: 'Page.item' }),
      props: {
        width: 'full',
        id: 'process',
        class: 'section-process',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: `<h2>Prosess</h2>`,
            },
            props: {
              class: 'heading container',
            },
          },

          {
            component: 'Text',
            condition: get({ path: 'customFields.process.description', source: 'Page.item' }),
            data: {
              content: get({ path: 'customFields.process.description', source: 'Page.item' }),
            },
            props: {
              class: 'intro',
              style: 'padding-inline: 1rem',
            },
          },

          {
            component: 'Grid',
            condition: get({ path: 'customFields.process.images.0', source: 'Page.item' }),
            props: {
              gap: '2rem',
              justifyContent: 'center',
              class: 'container ',
              style: 'padding-bottom: 4rem',
            },
            blocks: {
              default: [
                ...(get({ path: 'customFields.process.images', source: 'Page.item' }) || []).map(
                  i => {
                    return {
                      component: 'Image',

                      data: {
                        image: i,
                      },
                    }
                  },
                ),
              ],
            },
          },

          {
            component: 'Grid',
            condition:
              get({ path: 'customFields.process-steps.0.images.0', source: 'Page.item' }) ||
              get({ path: 'customFields.process-steps.0.description', source: 'Page.item' }),
            props: {
              gap: '0rem',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              columns: '1fr',
            },
            blocks: {
              default: [
                ...(get({ path: 'customFields.process-steps', source: 'Page.item' }) || []).map(
                  i => {
                    return {
                      component: 'ProcessItem',
                      data: {
                        ...i,
                      },
                    }
                  },
                ),
              ],
            },
          },

          {
            component: 'Grid',
            condition: get({
              path: 'customFields.process-images-below.0',
              source: 'Page.item',
            }),
            props: {
              gap: '2rem',
              justifyContent: 'center',
              style: 'padding-top: 3rem',
              class: 'container',
            },
            blocks: {
              default: [
                ...(
                  get({ path: 'customFields.process-images-below', source: 'Page.item' }) || []
                ).map(i => {
                  return {
                    component: 'Image',
                    data: {
                      image: i,
                    },
                  }
                }),
              ],
            },
          },
        ],
      },
    },

    {
      condition: (get({ path: 'customFields.delivery', source: 'Page.item' }) || []).length,
      component: 'Section',
      props: {
        width: 'medium',
        id: 'delivery',
        class: 'section-delivery np-b',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: '<h2>Leveranse</h2>',
            },
            props: {
              class: 'heading',
              style: 'max-width: 1400px;padding-inline: 1rem; width: 100%; margin-inline: auto;',
            },
          },

          {
            component: 'ImageText',
            data: {
              content: get({ path: 'customFields.delivery', source: 'Page.item' }),
            },
          },
        ],
      },
    },

    {
      condition: get({
        path: 'customFields.project-products.length',
        customFieldsConfig: 'offer-krogsveen',
      }),
      component: 'Section',
      props: {
        width: 'medium',
        id: 'offer',
        class: 'section-offer',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: `<h2>${get({
                path: 'title',
                customFieldsConfig: 'offer-krogsveen',
              })}</h2>`,
            },
            props: {
              class: 'heading',
            },
          },

          {
            component: 'Text',
            data: {
              content: get({
                path: 'content',
                customFieldsConfig: 'offer-krogsveen',
              }),
            },
            props: {
              class: 'intro',
            },
          },

          {
            condition: (get({
              path: 'customFields.project-products',
              customFieldsConfig: 'offer-krogsveen',
            }) || [{}]).some(i => i.description || i.cost),

            component: 'ProductTable',
            data: {
              content: get({
                path: 'customFields.project-products',
                customFieldsConfig: 'offer-krogsveen',
              }),
            },

            props: {
              columns: ['Produkt', 'Provisjon', 'Kostnad'],
              rows: ['description', 'commission', 'cost'],
              filterItems: [
                { key: 'cost', filter: 'currency' },
                { key: 'commission', filter: 'percent' },
              ],
              style: 'padding: 2rem 0;',
            },

            blocks: {
              top: {
                component: 'Text',
                data: {
                  content: get({
                    path: 'customFields.project-products-description.top',
                    customFieldsConfig: 'offer-krogsveen',
                  }),
                },
                props: {
                  class: 'product-table__additional-content-top',
                },
              },
              bottom: {
                component: 'Text',
                data: {
                  content: get({
                    path: 'customFields.project-products-description.bottom',
                    customFieldsConfig: 'offer-krogsveen',
                  }),
                },
                props: {
                  class: 'product-table__additional-content-bottom',
                },
              },
            },
          },

          {
            condition: (get({
              path: 'customFields.additional-products',
              customFieldsConfig: 'offer-krogsveen',
            }) || [{}]).some(i => i.description || i.cost),
            component: 'ProductTable',
            data: {
              content: get({
                path: 'customFields.additional-products',
                customFieldsConfig: 'offer-krogsveen',
              }),
            },

            props: {
              columns: ['Produkt', 'Kostnad'],
              rows: ['description', 'cost'],
              filterItems: [{ key: 'cost', filter: 'currency' }],
            },
            blocks: {
              top: {
                component: 'Text',
                props: {
                  class: 'product-table__additional-content-top',
                },
                data: {
                  content: get({
                    path: 'customFields.additional-products-description.top',
                    customFieldsConfig: 'offer-krogsveen',
                  }),
                },
              },
              bottom: {
                component: 'Text',
                data: {
                  content: get({
                    path: 'customFields.additional-products-description.bottom',
                    customFieldsConfig: 'offer-krogsveen',
                  }),
                },
                props: {
                  class: 'product-table__additional-content-bottom',
                },
              },
            },
          },
        ],
      },
    },

    {
      condition: get({ path: 'customFields.attachments.length', source: 'Page.item' }),
      component: 'Section',
      props: {
        width: 'medium',
        id: 'attachments',
        class: 'section-attachments',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: `<h2>Vedlegg</h2>`,
            },
            props: {
              class: 'heading',
            },
          },
          {
            component: 'Attachments',
            data: get({ path: 'customFields.attachments', source: 'Page.item' }),
          },
        ],
      },
    },

    {
      condition: get({
        path: 'customFields.reference-projects.0.image',
        source: 'Page.item',
      }),

      component: 'Section',
      props: {
        width: 'medium',
        id: 'references',
        class: 'section-references',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: '<h2>Referanser</h2>',
            },
            props: {
              class: 'heading',
            },
          },

          {
            component: 'Grid',
            props: {
              gap: '2rem',
              justifyContent: 'center',
            },
            blocks: {
              default: [
                ...(
                  get({
                    path: 'customFields.reference-projects',
                    source: 'Page.item',
                  }) || []
                ).map(i => {
                  return {
                    component: 'Link',
                    data: {
                      href: i.link,
                    },
                    blocks: {
                      default: {
                        component: 'Image',
                        data: {
                          image: i.image,
                        },
                        props: {
                          aspectRatio: '16/9',
                        },
                      },
                    },
                  }
                }),
              ],
            },
          },
        ],
      },
    },
  ]
}
