<template>
  <div class="product-table">
    <slot name="top" />
    <table class="table table--default table--padding">
      <thead>
        <tr>
          <th v-for="(col, index) in columns" :key="'tr_' + index" class="text--left text--nowrap">
            {{ col }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(e, index) in tableData" :key="'tr_' + index">
          <template v-if="e">
            <td
              v-for="(key, valueIndex) in rows"
              :key="'td_' + valueIndex"
              class="text--left text--nowrap"
            >
              {{ addFilter(e, key) }}
            </td>
          </template>
        </tr>

        <template v-if="total">
          <td class="product-table__summary-total-text text--left text--nowrap">
            <span>Total kostnad for tilleggstjenester</span>
            <span>Inklusive mva</span>
          </td>
          <td
            :colspan="columns.length"
            class="product-table__summary-total-cost text--right text--nowrap"
          >
            {{ total | Currency }}
          </td>
        </template>
      </tbody>
    </table>

    <slot name="bottom" />
  </div>
</template>

<script>
import { Currency, Percent } from '@/filters'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        content: [],
      }),
    },
    columns: {
      type: Array,
      default: () => [],
    },
    calc: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    filterItems: {
      type: Array,
      default: () => [],
    },
    unitsCount: Number,
  },
  computed: {
    tableData() {
      return (this.data.content || []).map(i => {
        return {
          ...i,
          rowTotalCost: this.getRowTotal(i),
        }
      })
    },
    total() {
      return this.tableData.reduce((previousValue, currentValue) => {
        return previousValue + currentValue.rowTotalCost
      }, 0)
    },
  },
  methods: {
    getRowTotal(e) {
      return this.calc.reduce((previousValue, currentValue) => {
        if (!e[currentValue]) return previousValue

        return previousValue + Number(e[currentValue])
      }, 0)
    },
    addFilter(item, key) {
      let match = this.filterItems.find(i => i.key === key)
      if (!match) return item[key]
      switch (match.filter) {
        case 'currency':
          return Currency(Number(item[key]))
        case 'percent':
          return Percent(item[key])
        default:
          return Number(item[key])
      }
    },
  },
  filters: {
    Currency,
    Percent,
  },
}
</script>

<style lang="scss">
.product-table {
  $tablePadding: 1.5rem;
  $tablePaddingResponsive: 0.9rem;
  overflow-y: auto;
  &__summary {
    display: flex;
    justify-content: space-between;

    &-total-text {
      font-size: 18px;
      display: flex;
      flex-direction: column;
      span + span {
        font-size: 14px;
      }
      @include respond-below('phone') {
        font-size: 16px;
      }
    }
    &-total-cost {
      font-size: 24px;
      @include respond-below('phone') {
        font-size: 16px;
      }
    }
  }
  td:last-child,
  th:last-child {
    text-align: right;
  }

  .product-table__additional-content {
    &-top {
      font-size: 22px;
      margin-bottom: 2rem;
    }
    &-bottom {
      margin-top: 2rem;
    }

    &-top,
    &-bottom {
      padding-left: $tablePadding;
      @include respond-below('phone') {
        padding-left: $tablePaddingResponsive;
      }
    }
  }

  .table--padding {
    td,
    th {
      padding: $tablePadding;
      @include respond-below('phone') {
        padding: $tablePaddingResponsive;
      }
    }
  }
}
</style>
